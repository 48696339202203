jQuery(function () {
    initScrollEvent();
    initListeners();
});

const scrollingevent = new CustomEvent('scrollingevent');
const scrolltopevent = new CustomEvent('scrolltopevent');

function initScrollEvent() {
    $(window).on('scroll', function (event) {
        var scroll = $(window).scrollTop();
        var headerHeight = $('#header').outerHeight();
        if (scroll > headerHeight) {
            document.dispatchEvent(scrollingevent);
            $('body').addClass('scrolling');
        } else {
            document.dispatchEvent(scrolltopevent);
            $('body').removeClass('scrolling');
        }
    });
}

function initListeners() {
    $(document).on('click', '.back-to-top', function (e) {
        e.preventDefault();
        $('body,html').animate({
            scrollTop: 0
        }, 600);
        return;
    });

    $(document).on('click', '.go-to-anchor[href]', function (e) {
        e.preventDefault();
        var selector = $(this).attr('href');
        if ($(selector).length > 0) {
            $('body,html').animate({
                scrollTop: $(selector).position().top
            }, 600);
        }
        return;
    });
}

$(".mdghome-slide-caption h2").each(function () {
    var elText,
        openSpan = '<span class="first-word">',
        closeSpan = '</span>';

    elText = $(this).text().split(" ");
    elText.unshift(openSpan);
    elText.splice(2, 0, closeSpan);
    elText = elText.join(" ");
    $(this).html(elText);
});